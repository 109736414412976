exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-team-js": () => import("./../../../src/pages/about-us/Team.js" /* webpackChunkName: "component---src-pages-about-us-team-js" */),
  "component---src-pages-career-career-language-js": () => import("./../../../src/pages/career/[CareerLanguage].js" /* webpackChunkName: "component---src-pages-career-career-language-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-careers-form-js": () => import("./../../../src/pages/CareersForm.js" /* webpackChunkName: "component---src-pages-careers-form-js" */),
  "component---src-pages-customer-engagement-capacity-js": () => import("./../../../src/pages/customer-engagement/Capacity.js" /* webpackChunkName: "component---src-pages-customer-engagement-capacity-js" */),
  "component---src-pages-customer-engagement-data-engineering-js": () => import("./../../../src/pages/customer-engagement/DataEngineering.js" /* webpackChunkName: "component---src-pages-customer-engagement-data-engineering-js" */),
  "component---src-pages-customer-engagement-hybrid-js": () => import("./../../../src/pages/customer-engagement/Hybrid.js" /* webpackChunkName: "component---src-pages-customer-engagement-hybrid-js" */),
  "component---src-pages-customer-engagement-index-js": () => import("./../../../src/pages/customer-engagement/index.js" /* webpackChunkName: "component---src-pages-customer-engagement-index-js" */),
  "component---src-pages-customer-engagement-product-consulting-js": () => import("./../../../src/pages/customer-engagement/ProductConsulting.js" /* webpackChunkName: "component---src-pages-customer-engagement-product-consulting-js" */),
  "component---src-pages-customer-engagement-product-js": () => import("./../../../src/pages/customer-engagement/Product.js" /* webpackChunkName: "component---src-pages-customer-engagement-product-js" */),
  "component---src-pages-customer-engagement-technology-js": () => import("./../../../src/pages/customer-engagement/Technology.js" /* webpackChunkName: "component---src-pages-customer-engagement-technology-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/Form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-page-banner-data-js": () => import("./../../../src/pages/LandingPage/BannerData.js" /* webpackChunkName: "component---src-pages-landing-page-banner-data-js" */),
  "component---src-pages-landing-page-capability-banner-js": () => import("./../../../src/pages/LandingPage/CapabilityBanner.js" /* webpackChunkName: "component---src-pages-landing-page-capability-banner-js" */),
  "component---src-pages-landing-page-clients-js": () => import("./../../../src/pages/LandingPage/Clients.js" /* webpackChunkName: "component---src-pages-landing-page-clients-js" */),
  "component---src-pages-landing-page-index-js": () => import("./../../../src/pages/LandingPage/index.js" /* webpackChunkName: "component---src-pages-landing-page-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */)
}

