import {
    createTheme
} from '@mui/material/styles';

export const PALETTE = {
    PRIMARY: '#BECE20',
    SECONDARY: '#19857b',
    TERITIARY: '#FFD203',
    QUATERNARY: '#AAAAAA',
    QUINARY: '#CCE4EB',
    SENARY: '#fff',
    SEPTENARY: '#666666',
    OCTONARY: '#ADADAD',
    PRIMARY_TEXT: '#000000',
    GREY: '#666666',
    WHITE: '#fff',
    OPAQUE_BLUE: '#CCE4EB',
    BLACK_VARIANT_ONE: '#2C2A29',
    OPAQUE_PRIMARY: '#CCE4EB',
    BLACK_SHADE_1: '#2C2A29',
    ERROR: '#ff3333',
    DARK_GRAY: '#5A5A5A',
    // WHITE_SMOKE: '#F5F5F5',
    NIGHT_RIDER: '#343333',
    BLACK_CORAL: '#4F5B60',
    SOLITUDE: '#ECEFF6',
    HAWKES_BLUE: '#D5DAE1',
    NERO: '#232222',
    MEDIUN_DARK_GRAY: '#575757',
    LIGHT_GRAY: '#D6D5E1',
    BORDER_GREY: '#757575'
};

const FONT = {
    FAMILY: {
        RALEWAY: 'Raleway',
        POPPINS: 'Poppins',
        PTSANS: 'PT Sans'
    },
    SIZES: {
        12: '0.75rem',
        14: '0.9rem',
        16: '1rem',
        18: '1.125rem',
        20: '1.25rem',
        22: '1.375rem',
        24: '1.5rem',
        36: '2.25rem',
        30: '1.875rem',
        19: '1.188rem',
        44: '2.75rem',
        56: '3.5rem'
    },
    WEIGHT: {
        BOLD: 800,
        SEMI_BOLD: 700,
        DEMI_BOLD: 600,
        MEDIUM: 500,
        REGULAR: 400,
        LIGHT: 300
    }
};

const theme = createTheme({
    typography: {
        h1: {
            textAlign: 'center',
            margin: 'auto',
            paddingTop: '1rem',

            fontFamily: FONT.FAMILY.RALEWAY,

            letterSpacing: '1.5px',
            fontWeight: FONT.WEIGHT.MEDIUM,
            fontSize: FONT.SIZES[36],
            color: PALETTE.NIGHT_RIDER,
            lineHeight: '145%',
            '@media (max-width: 320px)': {
                fontSize: FONT.SIZES[30],
                letterSpacing: '0.5px'
            }
        },
        h2: {
            textAlign: 'center',
            margin: 'auto',

            fontFamily: FONT.FAMILY.RALEWAY,
            marginBottom: '1rem',
            letterSpacing: '2px',
            fontWeight: FONT.WEIGHT.MEDIUM,
            fontSize: FONT.SIZES[36],
            color: PALETTE.NIGHT_RIDER,
            lineHeight: '145%'
        },
        h3: {
            fontWeight: FONT.WEIGHT.DEMI_BOLD,
            fontSize: FONT.SIZES[18],

            color: PALETTE.BLACK_SHADE_1,
            padding: '1rem',
            lineHeight: '145%',
            letterSpacing: '2px',

            margin: 'auto',
            fontFamily: FONT.FAMILY.POPPINS
        },
        h4: {
            fontSize: FONT.SIZES[56],
            color: PALETTE.NIGHT_RIDER,
            lineHeight: '130%',
            padding: '2.5rem 2.5rem',
            fontFamily: 'Raleway',
            fontWeight: FONT.WEIGHT.MEDIUM,
            letterSpacing: '2.5px',
            '@media (min-width: 320px) and (max-width:375px)': {
                fontSize: '1.3rem !important',
                lineHeight: '130%',

                padding: 0
            },
            '@media (min-width: 375px)and (max-width:420px)': {
                fontSize: '1.66rem !important',
                lineHeight: '130%',
                padding: 0
            },
            '@media (min-width: 420px)and (max-width:768px)': {
                fontSize: '1.9rem !important',
                marginTop: '1.7rem',
                lineHeight: '130%',
                padding: 0
            },
            '@media (min-width: 768px)and (max-width:1024px)': {
                fontSize: '2.7rem !important',
                marginTop: '1.7rem',
                lineHeight: '130%',
                padding: 0
            }
        },
        h5: {
            fontSize: '2rem',
            color: PALETTE.NIGHT_RIDER,
            marginTop: '10px',
            padding: '1rem 0rem',
            fontFamily: 'Raleway',
            letterSpacing: '2.2px',
            fontWeight: FONT.WEIGHT.MEDIUM,
            '@media (min-width: 280px) and (max-width:315px)': {
                fontSize: '1rem !important',
                marginTop: '2rem'
            },

            '@media (min-width: 320px) and (max-width:360px)': {
                fontSize: '1.2rem !important',
                marginTop: '5rem'
            },
            '@media (min-width: 360px) and (max-width:375px)': {
                fontSize: '1.4rem !important',
                marginTop: '5rem'
            },
            '@media (min-width: 375px) and (max-width:425px)': {
                fontSize: '1.5rem !important',
                marginTop: '5rem'
            },
            '@media (min-width: 425px) and (max-width:768px)': {
                fontSize: '1.766rem !important'
            },
            '@media (min-width: 768px) and (max-width:1024px)': {
                fontSize: '1.55rem !important'
            },
            '@media (min-width: 1024px) and (max-width:1024px)': {
                fontSize: '1rem !important'
            }
        },

        h6: {
            fontWeight: FONT.WEIGHT.SEMI_BOLD,
            fontSize: FONT.SIZES[30],
            color: PALETTE.NIGHT_RIDER,
            paddingLeft: '2rem',
            letterSpacing: '1.5px',
            fontFamily: FONT.FAMILY.RALEWAY,
            zIndex: 10,
            '@media (min-width: 320px) and (max-width:375px)': {
                fontSize: '1.2rem',
                marginTop: '0.77rem'
            },
            '@media (min-width: 375px) and (max-width:425px)': {
                fontSize: '1.5rem',
                marginTop: '0.77rem'
            },
            '@media (min-width: 425px) and (max-width:768px)': {
                fontSize: '1.8rem',
                marginTop: '0.77rem'
            },
            '@media (min-width: 768px) and (max-width:1025px)': {
                fontSize: '1.5rem !important'
            }
        },
        h7: {
            fontWeight: FONT.WEIGHT.DEMI_BOLD,
            fontSize: FONT.SIZES[30],
            lineHeight: '145%',
            color: PALETTE.NIGHT_RIDER,
            letterSpacing: '2px',
            fontFamily: 'Poppins',
            display: 'flex',
            // paddingBottom: '1rem',
            '@media (max-width: 320px)': {
                fontSize: '1.5rem',
                letterSpacing: '0px'
            },
            '@media (max-width: 375px)': {
                fontSize: '1.5rem',
                letterSpacing: '0px'
            }
            // '@media (max-width: 320px)': {
            //     fontSize: '1.5rem',
            //     letterSpacing: '0px'
            // }
        },
        h8: {
            fontSize: '14px',
            lineHeight: '145%',
            letterSpacing: '1px',
            color: '#4F5B60',
            fontWeight: '600',
            fontFamily: 'Poppins',
            marginBottom: '1px'
        },
        h9: {
            fontWeight: FONT.REGULAR,
            fontSize: '14px',
            lineHeight: '2px !important',
            color: '#4F5B60',
            fontFamily: 'Poppins',
            justifyContent: 'center'
        },
        h10: {
            fontSize: '0.72rem',
            letterSPacing: '1px',
            color: '#343333',
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontWeight: FONT.WEIGHT.REGULAR
        },
        h12: {
            fontWeight: FONT.WEIGHT.MEDIUM,
            fontSize: FONT.SIZES[30],
            color: PALETTE.NIGHT_RIDER,
            paddingLeft: '5.5rem',
            letterSpacing: '1.5px',

            fontFamily: FONT.FAMILY.RALEWAY,
            zIndex: 10
        },
        span: {
            fontWeight: FONT.WEIGHT.RALEWAY,
            fontSize: FONT.SIZES[36],
            lineHeight: '145%',
            color: PALETTE.PRIMARY
        },
        bodyText10: {
            textAlign: 'center',
            marginTop: '3rem',
            color: '#343333',
            fontFamily: 'Poppins',

            fontWeight: FONT.WEIGHT.MEDIUM
        },
        bodyText1: {
            fontSize: FONT.SIZES[12],

            color: PALETTE.MEDIUN_DARK_GRAY,
            padding: '1rem',
            fontWeight: '600',
            paddingBottom: '0.5rem',
            margin: '1px',
            fontFamily: 'Poppins'
        },
        bodyText2: {
            fontWeight: FONT.REGULAR,
            fontSize: FONT.SIZES[16],
            lineHeight: '145%',
            letterSpacing: '1.1px',
            color: '#343333',
            fontFamily: 'Poppins',
            textAlign: 'justify',
            '@media (max-width: 320px)': {
                fontSize: FONT.SIZES[12],
                letterSpacing: '0px'
            }
        },
        bodyText12: {
            fontWeight: FONT.REGULAR,
            fontSize: FONT.SIZES[16],
            lineHeight: '210%',
            letterSpacing: '1.5px',
            color: '#4F5B60',
            fontFamily: FONT.FAMILY.RALEWAY,

            marginBottom: '15px',
            '&:hover': {
                color: `${PALETTE.PRIMARY}`,
                cursor: 'pointer',
                fontWeight: FONT.BOLD
            },
            '@media screen and (max-width: 320px)': {
                display: 'flex'
            }
        },
        bodyText3: {
            color: PALETTE.NIGHT_RIDER,
            margin: 'auto',
            fontWeight: FONT.WEIGHT.REGULAR,
            fontSize: FONT.SIZES[18],
            justifyContent: 'center',

            textAlign: 'center',
            marginBottom: '2rem',
            marginTop: '1rem',

            fontFamily: FONT.FAMILY.POPPINS,
            '@media (min-width: 320px) and (max-width:375px)': {
                textAlign: 'left',
                fontSize: '1rem'
            },
            '@media (min-width: 375px)and (max-width:425px)': {
                textAlign: 'left',
                fontSize: '1rem'
            },
            '@media (min-width: 425px)and (max-width:768px)': {
                textAlign: 'left'
            }
        },
        bodyText4: {
            fontSize: '0.89rem',

            letterSPacing: '0.1px',
            lineHeight: '31px',
            color: '#343333',
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontWeight: FONT.WEIGHT.DEMI_BOLD,
            '@media (min-width: 280px) and (max-width:315px)': {
                lineHeight: '12px',

                // marginTop: '8px',
                fontSize: '0.5rem'
            },
            '@media (min-width: 320px) and (max-width:375px)': {
                lineHeight: '12px',

                // marginTop: '8px',
                fontSize: '0.6rem'
            },
            '@media (min-width: 375px)and (max-width:425px)': {
                fontSize: '0.6rem !important',
                lineHeight: '12px'
            },
            '@media (min-width: 425px)and (max-width:768px)': {
                fontSize: '0.71rem !important',
                lineHeight: '13px'
            },
            '@media (min-width: 768px)and (max-width:1025px)': {
                fontSize: '0.6rem !important',
                marginTop: '8px !important'
            }
            // '@media (min-width: 1024px)and (max-width:1030px)': {
            //     fontSize: '0.6rem !important',
            //     marginTop: '8px !important'
            // }
        },
        bodyText5: {
            fontWeight: FONT.WEIGHT.REGULAR,
            fontSize: FONT.SIZES[16],
            lineHeight: '0.8575 rem',
            color: '#343333',
            fontFamily: 'Poppins'
        },
        bodyText6: {
            fontWeight: FONT.WEIGHT.REGULAR,
            fontSize: '16px !important',
            lineHeight: '0.8575 rem',
            color: PALETTE.NIGHT_RIDER,
            fontFamily: 'Poppins'
        },
        bodyText7: {
            fontWeight: FONT.WEIGHT.MEDIUM,
            fontSize: '14px',
            color: '#5F6368',
            fontFamily: 'Poppins',
            marginBottom: '12px',
            letterSpacing: '1px',
            marginLeft: '10px',
            '@media (max-width: 320px)': {
                fontSize: '0.6rem'
            },
            '@media (max-width: 375px)': {
                fontSize: '0.68rem',
                letterSpacing: '0px'
            },
            '@media (max-width: 425px)': {
                fontSize: '0.68rem !important',
                letterSpacing: '0px'
            }
        },
        bodyText11: {
            textAlign: 'center',
            marginTop: '3rem',
            color: '#343333',
            fontFamily: 'Poppins',
            fontWeight: '500px !important',
            marginBottom: '1rem'
        },
        bodyText8: {
            fontWeight: FONT.WEIGHT.LIGHT,
            fontSize: '0.7rem',
            color: PALETTE.MEDIUN_DARK_GRAY,

            padding: 'auto',
            fontFamily: FONT.FAMILY.POPPINS,
            '@media (max-width: 320px)': {
                fontSize: '0.5rem !important',
                letterSpacing: '0px'
            },
            '@media (max-width: 375px)': {
                fontSize: '0.55rem !important',
                letterSpacing: '0px'
            },
            '@media (max-width: 1024px)': {
                fontSize: '0.59rem !important',
                letterSpacing: '0px'
            }
        },
        bodyText9: {
            fontWeight: FONT.WEIGHT.LIGHT,
            fontSize: FONT.SIZES[12],
            color: PALETTE.MEDIUN_DARK_GRAY,
            letterSpacing: '0px',
            lineHeight: '145%',
            textAlign: 'center',
            margin: 0,
            fontFamily: FONT.FAMILY.POPPINS
        },
        subTitle9: {
            textAlign: 'center',
            marginTop: '2rem',
            color: '#343333',
            fontFamily: 'Poppins'
        },
        subTitle5: {
            fontSize: FONT.SIZES[20],
            color: '#4F5B60',

            fontWeight: FONT.WEIGHT.BOLD,
            letterSpacing: '1px',
            fontFamily: 'Raleway',
            zIndex: 10
        },
        subTitle7: {
            fontWeight: FONT.WEIGHT.DEMI_BOLD,
            fontSize: FONT.SIZES[14],
            lineHeight: '145%',
            color: '#343A40',
            fontFamily: 'Poppins',
            marginLeft: '21px',
            letterSpacing: '1.5px'
        },
        subTitle6: {
            fontWeight: FONT.WEIGHT.DEMI_BOLD,
            fontSize: FONT.SIZES[20],
            // paddingTop: '3rem',
            lineHeight: '160%',
            color: PALETTE.BLACK_SHADE_1,
            fontFamily: 'Poppins',
            // marginBottom: '1rem',
            '@media (max-width: 320px)': {}
        },
        subTitle2: {
            fontWeight: FONT.WEIGHT.DEMI_BOLD,
            fontSize: FONT.SIZES[19],
            lineHeight: '160%',
            color: PALETTE.BLACK_SHADE_1,
            textTransform: 'capitalize'
        },
        subTitle3: {
            fontWeight: FONT.WEIGHT.SEMI_BOLD,
            fontSize: FONT.SIZES[16],
            color: PALETTE.NIGHT_RIDER,
            margin: '0',
            textAlign: 'center',
            letterSpacing: '2px',

            fontFamily: FONT.FAMILY.RALEWAY
        },
        subTitle8: {
            fontWeight: FONT.WEIGHT.MEDIUM,
            fontSize: FONT.SIZES[44],
            lineHeight: '130%',
            color: PALETTE.BLACK_SHADE_1,
            fontFamily: 'Poppins',
            paddingRight: '50px',
            '@media (min-width: 320px) and (max-width:375px)': {
                fontSize: '1rem !important'
            },
            '@media (min-width: 375px) and (max-width:425px)': {
                fontSize: '1rem !important'
            },
            '@media (min-width: 425px) and (max-width:768px)': {
                fontSize: '1rem !important'
            },
            '@media (min-width: 769px) and (max-width:1024px)': {
                fontSize: '2rem !important'
            }
        },
        subTitle1: {
            fontWeight: FONT.WEIGHT.DEMI_BOLD,
            fontSize: FONT.SIZES[44],
            color: PALETTE.PRIMARY,
            lineHeight: '130%',
            fontFamily: FONT.FAMILY.RALEWAY,
            zIndex: 10,
            letterSpacing: '2px',
            '@media (min-width: 320px) and (max-width:375px)': {
                fontSize: '1rem !important'
            },
            '@media (min-width: 375px) and (max-width:425px)': {
                fontSize: '1rem !important'
            },
            '@media (min-width: 425px) and (max-width:768px)': {
                fontSize: '1rem !important'
            },
            '@media (min-width: 769px) and (max-width:1024px)': {
                fontSize: '2rem !important'
            }
        },
        subTitle12: {
            fontWeight: FONT.WEIGHT.BOLD,
            fontSize: FONT.SIZES[30],
            color: PALETTE.PRIMARY,
            lineHeight: '80%',
            letterSpacing: '1.5px'
        },
        reachdata: {
            fontWeight: '500 ',
            fontSize: '1.5rem ',
            color: '#343333',
            letterSpacing: '2px',

            fontFamily: 'Raleway',
            zIndex: 10
        },
        compName: {
            fontSize: '12px !important',
            fontWeight: '800 !important',
            fontFamily: 'Raleway !important',
            paddingTop: '1px',
            color: '#343A40!important',
            lineHeight: '200% !important'
        },
        banloc: {
            fontSize: '15px !important',
            fontFamily: 'Poppins !important',
            color: '#5F6368',
            float: 'center',

            letterSpacing: '1px',
            '&:hover': {
                color: '#A6B50C',
                cursor: 'pointer'
            },
            '@media screen and (max-width: 320px)': {
                fontSize: '13px !important',
                justifyContent: 'center'
            },
            '@media screen and (max-width: 1204px)': {
                fontSize: '13px !important',
                justifyContent: 'center'
            }
        },
        mloc: {
            fontSize: '15px !important',
            fontFamily: 'Poppins !important',
            color: '#5F6368',

            textAlign: 'center',
            letterSpacing: '1px',
            '&:hover': {
                color: '#A6B50C',
                cursor: 'pointer'
            },
            '@media screen and (max-width: 320px)': {
                fontSize: '13px !important',
                justifyContent: 'center'
            },
            '@media screen and (max-width: 1204px)': {
                fontSize: '13px !important',
                justifyContent: 'center'
            }
        },
        EmailIcon: {
            marginTop: '5px',
            fontSize: '0.8rem',
            fontFamily: 'Poppins !important',
            fontWeight: '400 !important',
            color: '#4F5B60 !important',
            '&:hover': {
                color: '#A6B50C !important',
                cursor: 'pointer'
            },
            '@media screen and (max-width: 320px)': {
                fontSize: '10px !important',
                justifyContent: 'center'
            },
            '@media screen and (max-width: 375px)': {
                fontSize: '10px !important',
                justifyContent: 'center'
            },
            '@media screen and (max-width: 425px)': {
                fontSize: '10px !important',
                justifyContent: 'center'
            },
            '@media screen and (max-width: 768px)': {
                fontSize: '11px !important'
            },
            '@media screen and (max-width: 1024px)': {
                fontSize: '11px !important'
            }
        },
        designation: {
            textAlign: 'center',
            fontSize: '12px !important',
            color: '#5A5A5A',
            fontFamily: 'Poppins !important',
            display: 'flex',
            justifyContent: 'center'
        },
        circleMenuStyle: {
            backgroundColor: 'red'
        },
        name: {
            textAlign: 'center',
            margin: '5px !important',
            color: '#5A5A5A',
            fontFamily: 'Poppins!important',
            fontSize: '16px',
            fontWeight: '600 !important',
            display: 'flex',
            justifyContent: 'center',
            '@media (min-width: 320px) and (max-width:375px)': {
                fontSize: '11px !important',
                letterSpacing: '0.2px'
            },
            '@media (min-width: 375px) and (max-width:425px)': {
                fontSize: '12px !important',
                letterSpacing: '0.2px'
            },
            '@media (min-width: 425px) and (max-width:700px)': {
                fontSize: '12px !important',
                letterSpacing: '0.2px'
            }
        }
    },
    // Button //
    components: {
        MuiButton: {
            variants: [{
                    props: {
                        variant: 'contained'
                    },
                    style: {
                        minWidth: '8rem !important',
                        height: '2.2rem !important',
                        color: PALETTE.SENARY,
                        fontWeight: FONT.WEIGHT.BOLD,
                        fontSize: '12px !important',
                        letterSpacing: '1px !important',
                        textAlign: 'center !important',
                        backgroundColor: PALETTE.PRIMARY,
                        fontFamily: 'Poppins !important',
                        boxShadow: 'none !important',

                        '&:hover': {
                            backgroundColor: '#fff !important',
                            color: PALETTE.PRIMARY,
                            boxShadow: 'none !important',
                            border: '2px solid #BECE20 !important'
                        }
                    }
                },
                {
                    props: {
                        variant: 'contained1'
                    },
                    style: {
                        minWidth: '1rem !important',
                        height: '1.5rem !important',
                        color: PALETTE.SENARY,
                        fontWeight: FONT.WEIGHT.BOLD,
                        fontSize: '12px !important',
                        letterSpacing: '1px !important',
                        textAlign: 'center !important',
                        backgroundColor: PALETTE.PRIMARY,
                        fontFamily: 'Poppins !important',
                        boxShadow: 'none !important',

                        '&:hover': {
                            backgroundColor: '#fff !important',
                            color: PALETTE.PRIMARY,
                            boxShadow: 'none !important',
                            border: '2px solid #BECE20 !important'
                        }
                    }
                },

                {
                    props: {
                        variant: 'form'
                    },
                    style: {
                        textAlign: 'center',
                        margin: '0.5rem 0',
                        width: '130px !important',
                        height: '40px !important',
                        color: PALETTE.NIGHT_RIDER,
                        fontWeight: FONT.WEIGHT.MEDIUM,
                        fontSize: FONT.SIZES[12],
                        lineHeight: '145%',
                        textTransform: 'initial',
                        backgroundColor: PALETTE.LIGHT_GRAY,
                        fontFamily: 'Poppins !important',
                        float: 'right',
                        '&:hover': {
                            backgroundColor: PALETTE.PRIMARY
                        }
                    }
                },
                {
                    props: {
                        variant: 'data'
                    },
                    style: {
                        color: '#F2F2F2',
                        fontWeight: FONT.WEIGHT.BOLD,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Raleway',
                        letterSpacing: '1.5px',
                        border: '2.5px solid #757575',
                        height: '40px',
                        backgroundColor: '#4F5B60',
                        borderRadius: '3.12rem',
                        textAlign: 'center',
                        justifyContent: 'center',
                        width: 'auto',
                        // width: '200px',
                        textTransform: 'initial',

                        '&:hover': {
                            display: 'inlineBlock',

                            backgroundColor: '#BECE20',
                            color: '#4F5B60',

                            // boxShadow: 'inset 0 0 0 1px #000 0 0 0 1px #27d9b4',

                            boxShadow: 'inset 0px 0px 5px 1px #606914',

                            border: '2.5px solid #BECE20'
                        }
                    }
                },
                {
                    props: {
                        variant: 'carrer_button'
                    },
                    style: {
                        padding: '8px 8px',
                        color: '#757575',
                        fontWeight: FONT.WEIGHT.BOLD,
                        fontSize: '12px',
                        fontFamily: 'Raleway',
                        letterSpacing: '1px',
                        border: '1px solid #757575',

                        boxShadow: 'inset 0.5px 0.5px 0.5px #757575',

                        height: '1.8rem',
                        backgroundColor: '#D6D5E1',
                        borderRadius: '3.12rem',
                        textAlign: 'center',
                        textTransform: 'initial !important',

                        '&:hover': {
                            backgroundColor: PALETTE.PRIMARY,
                            color: PALETTE.WHITE,
                            boxShadow: 'none !important'
                        }
                    }
                },
                {
                    props: {
                        variant: 'back_button'
                    },
                    style: {
                        // padding: '1rem 0',
                        color: '#343333',
                        fontWeight: FONT.WEIGHT.MEDIUM,
                        fontSize: FONT.SIZES[18],
                        fontFamily: 'Poppins',
                        letterSpacing: '1.5px',

                        textAlign: 'center',

                        textTransform: 'initial',

                        '&:hover': {
                            color: PALETTE.PRIMARY,
                            cursor: 'pointer'
                        }
                    }
                },
                {
                    props: {
                        variant: 'footers'
                    },
                    style: {
                        height: '1px',

                        fontSize: FONT.SIZES[14],
                        fontFamily: 'Poppins',
                        color: PALETTE.BLACK_CORAL,

                        textTransform: 'initial',

                        '&:hover': {
                            textDecoration: 'none',
                            backgroundColor: '#ECEFF6',

                            color: PALETTE.PRIMARY,
                            disableRipple: 'true'
                        }
                    }
                },
                {
                    props: {
                        variant: 'chip'
                    },
                    style: {
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins !important',

                        marginTop: '0.3rem !important',
                        marginBottom: '0.3rem !important',

                        color: '#343333 !important',

                        backgroundColor: '#D6D5E1 !important',
                        borderRadius: '4rem !important',
                        textTransform: 'initial !important',
                        width: '99% !important',

                        cursor: 'default',
                        '&:hover': {
                            backgroundColor: '#D6D5E1',
                            cursor: 'default',
                            fontWeight: FONT.WEIGHT.DEMI_BOLD
                        }
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_1'
                    },
                    style: {
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins !important',
                        size: 'large !important',
                        marginRight: '15px !important',
                        cursor: 'default !important',
                        marginTop: '0.5rem !important',
                        marginBottom: '0.5rem !important',
                        justifyContent: 'left !important',
                        backgroundColor: '#D6D5E1 !important',
                        borderRadius: '4rem !important',
                        textTransform: 'initial !important',
                        width: '100% !important',
                        '&:hover': {
                            backgroundColor: '#D6D5E1',
                            cursor: 'default',
                            fontWeight: FONT.WEIGHT.DEMI_BOLD
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            width: '100% !important'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            width: '100% !important'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            width: '100% !important'
                        }
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_2'
                    },
                    style: {
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins !important',
                        size: 'large !important',
                        marginRight: '15px !important',
                        marginTop: '0.5rem !important',
                        marginBottom: '0.5rem !important',
                        justifyContent: 'left !important',
                        backgroundColor: '#D6D5E1 !important',
                        borderRadius: '4rem !important',
                        textTransform: 'initial !important',
                        width: '82% !important',
                        cursor: 'default !important',
                        '&:hover': {
                            backgroundColor: '#D6D5E1 !important',
                            cursor: 'default !important',
                            fontWeight: FONT.WEIGHT.DEMI_BOLD
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            width: '100% !important'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            width: '100% !important'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            width: '100% !important'
                        }
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_3'
                    },
                    style: {
                        // padding: '1rem 0',
                        marginRight: '15px !important',
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins !important',
                        cursor: 'default !important',
                        size: 'large !important',
                        width: '90% !important',
                        marginTop: '0.5rem !important',
                        marginBottom: '0.5rem !important',
                        justifyContent: 'left !important',
                        backgroundColor: '#D6D5E1 !important',
                        borderRadius: '4rem !important',
                        textTransform: 'initial !important',
                        '&:hover': {
                            backgroundColor: '#D6D5E1 !important',
                            cursor: 'default !important',
                            fontWeight: FONT.WEIGHT.DEMI_BOLD
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            width: '100% !important'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            width: '100% !important'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            width: '100% !important'
                        }
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_featur1'
                    },
                    style: {
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins',
                        size: 'large',
                        cursor: 'default',

                        marginTop: '0.3rem',
                        marginBottom: '0.3rem',

                        // margin: "1rem",
                        backgroundColor: '#D6D5E1',
                        borderRadius: '4rem',
                        textTransform: 'initial',
                        width: '99%',
                        '&:hover': {
                            backgroundColor: '#D6D5E1',
                            cursor: 'default',
                            fontWeight: FONT.WEIGHT.DEMI_BOLD
                        }
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_featur2'
                    },
                    style: {
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[14],
                        fontFamily: 'Poppins',
                        size: 'large',

                        margin: '1rem',
                        backgroundColor: '#D6D5E1',
                        borderRadius: '4rem',
                        textTransform: 'initial',
                        width: '800px',
                        cursor: 'default',
                        '&:hover': {
                            backgroundColor: '#D6D5E1',
                            cursor: 'default'
                        }
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_featur3'
                    },
                    style: {
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[14],
                        fontFamily: 'Poppins',
                        size: 'large',
                        cursor: 'default',
                        margin: '1rem',
                        backgroundColor: '#D6D5E1',
                        borderRadius: '4rem',
                        textTransform: 'initial',
                        minWidth: '200px',
                        '&:hover': {
                            backgroundColor: '#D6D5E1',
                            cursor: 'default'
                        }
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_5'
                    },
                    style: {
                        // padding: '1rem 0',

                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins',
                        size: 'large',
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',

                        backgroundColor: '#D6D5E1',
                        borderRadius: '4rem',
                        textTransform: 'initial',
                        width: '50%',

                        justifyContent: 'left',
                        cursor: 'default',
                        '&:hover': {
                            backgroundColor: '#D6D5E1',
                            cursor: 'default',
                            fontWeight: FONT.WEIGHT.DEMI_BOLD
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            width: '100%'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            width: '100%'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            width: '75%'
                        }
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_6'
                    },
                    style: {
                        cursor: 'default',
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins',
                        size: 'large',
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        backgroundColor: '#D6D5E1',
                        borderRadius: '4rem',
                        textTransform: 'initial',
                        width: '60%',
                        justifyContent: 'left',
                        '&:hover': {
                            backgroundColor: '#D6D5E1',
                            cursor: 'default',
                            fontWeight: FONT.WEIGHT.DEMI_BOLD
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            width: '100%'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            width: '100%'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            width: '90%'
                        }
                    }
                },

                {
                    props: {
                        variant: 'showButton'
                    },
                    style: {
                        display: 'block',
                        position: 'fixed',
                        bottom: '20px',
                        right: '30px',
                        zIndex: '99',
                        fontSize: '18px',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'red',
                        color: 'white',
                        cursor: 'pointer',
                        padding: '15px',
                        borderRadius: '4px'
                    }
                },
                {
                    props: {
                        variant: 'Hybrid_chip_7'
                    },
                    style: {
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins',
                        size: 'large',
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        backgroundColor: '#D6D5E1',
                        borderRadius: '4rem',
                        textTransform: 'initial',
                        width: '70%',
                        justifyContent: 'left',
                        cursor: 'default',
                        '&:hover': {
                            backgroundColor: '#D6D5E1',
                            cursor: 'default',
                            fontWeight: FONT.WEIGHT.DEMI_BOLD
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            width: '100%'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            width: '100%'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            width: '100%'
                        }
                    }
                },
                {
                    props: {
                        variant: 'text'
                    },
                    style: {
                        color: PALETTE.PRIMARY,
                        fontWeight: FONT.WEIGHT.BOLD,
                        fontSize: '12px',
                        marginTop: '2px',
                        letterSpacing: '0.6px',
                        float: 'right',

                        fontFamily: 'Poppins',
                        textTransform: 'inherit',
                        '&:hover': {
                            backgroundColor: 'white !important',
                            fontSize: '12.5px'
                        },
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        paddingRight: '23px',
                        paddingBottom: '5px'
                    }
                },
                {
                    props: {
                        variant: 'view_button'
                    },
                    style: {
                        color: PALETTE.PRIMARY,
                        fontWeight: FONT.WEIGHT.BOLD,
                        fontSize: FONT.SIZES[12],
                        marginTop: '-2rem',
                        letterSpacing: '0.6px',
                        float: 'right',
                        bottom: '-45px',

                        fontFamily: 'Poppins',
                        textTransform: 'inherit',
                        '&:hover': {
                            backgroundColor: 'white !important',
                            fontSize: '13px'
                        }
                    }
                }
            ]
        },

        MuiFormControlLabel: {
            styleOverrides: {
                props: {
                    className: 'select-underline'
                },
                label: {
                    fontSize: FONT.SIZES[20],
                    fontWeight: FONT.WEIGHT.REGULAR,
                    color: PALETTE.BLACK_SHADE_1,
                    lineHeight: '181%'
                }
            }
        },
        MuiFormControl: {
            variants: [{
                props: {
                    classes: 'form_select'
                },
                style: {
                    margin: '0.5rem 0',
                    minWidth: 280,
                    '@media (min-width: 320px) and (max-width:375px)': {
                        minWidth: 180
                    },
                    '@media (min-width: 375px) and (max-width:400px)': {
                        minWidth: 230
                    }
                }
            }]
        },
        MuiSvgIcon: {
            variants: [{
                props: {
                    classes: 'bicon',
                    style: {
                        fontSize: '18px !important',
                        float: 'left',
                        mt: '2px !important',
                        marginRight: '3px !important',
                        '@media screen and (max-width: 320px)': {
                            fontSize: '13px !important'
                        }
                    }
                }
            }]
        },

        MuiGrid: {
            variants: [{
                    props: {
                        classes: 'grid_footer'
                    },
                    style: {
                        paddingTop: '10px !important',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            paddingTop: '0px !important'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            paddingTop: '0px !important'
                        }
                    }
                },
                {
                    props: {
                        classes: 'address'
                    },
                    style: {
                        justifyContent: 'center',
                        alignContent: 'center',
                        textAlign: 'center',
                        marginTop: '20px',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            textAlign: 'center',
                            paddingTop: '0px'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            textAlign: 'center',
                            paddingTop: '0px'
                        },
                        '@media (min-width: 425px) and (max-width:760px)': {
                            textAlign: 'center',
                            paddingTop: '0px'
                        }
                    }
                },
                {
                    props: {
                        classes: 'privacy'
                    },
                    style: {
                        '@media (min-width: 320px) and (max-width:375px)': {
                            textAlign: 'center',
                            marginTop: '110px'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            textAlign: 'center',
                            marginTop: '85px'
                        },
                        '@media (min-width: 425px) and (max-width:599px)': {
                            textAlign: 'center',
                            marginTop: '85px'
                        }
                    }
                },
                {
                    props: {
                        classes: 'about_grid'
                    },
                    style: {
                        '@media screen and (max-width: 320px)': {
                            marginBottom: '-35px'
                        },
                        '@media screen and (max-width: 375px)': {
                            marginBottom: '-35px'
                        },
                        '@media screen and (max-width: 425px)': {
                            marginBottom: '-35px'
                        }
                    }
                },

                {
                    props: {
                        classes: 'grid-bg'
                    },
                    style: {
                        marginTop: '-7rem',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            margin: '15px !important',
                            width: '250px !important'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            margin: '20px !important',
                            width: '250px !important'
                        },

                        '@media (min-width: 425px) and (max-width:768px)': {
                            float: 'right',
                            margin: '0px 20px !important',
                            width: '250px !important'
                        }
                    }
                },
                {
                    props: {
                        classes: 'product_education_banner_sub'
                    },
                    style: {
                        overflow: 'hidden',

                        height: '100%',
                        paddingTop: '2rem',
                        paddingBottom: '2rem',

                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'relative',
                        zIndex: '0',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        }
                    }
                },

                {
                    props: {
                        classes: 'title_grid'
                    },
                    style: {
                        mt: '40px',
                        marginBottom: '10px !important',
                        marginLeft: '20px',
                        // mr: '-0.1rem',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            paddingLeft: '10px !important',
                            marginLeft: '0px !important'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            paddingLeft: '10px !important',
                            marginLeft: '0px !important'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            paddingLeft: '10px !important',
                            marginLeft: '0px !important'
                        }
                    }
                },

                {
                    props: {
                        classes: 'icons'
                    },
                    style: {
                        float: 'right',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: '20px !important',
                            marginRight: '25px'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: '20px !important',
                            marginRight: '120px'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            marginTop: '20px !important',
                            marginRight: '130px'
                        }
                    }
                },
                {
                    props: {
                        classes: 'careers_grid_section'
                    },
                    style: {
                        '@media (min-width: 768px) and (max-width:1200px)': {
                            marginTop: '15px !important'
                        }
                    }
                },
                {
                    props: {
                        classes: 'footer'
                    },
                    style: {
                        overflow: 'hidden',
                        background: PALETTE.SOLITUDE,
                        height: 'auto',
                        bottom: 0,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'relative'
                    }
                },
                {
                    props: {
                        classes: 'imageAlign'
                    },
                    style: {
                        paddingTop: '17px !important',
                        justifyContent: 'center',
                        // '@media (min-width: 280px) and (max-width:315px)': {
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        //     alignItems: 'center'
                        // },
                        // '@media (min-width: 320px) and (max-width:375px)': {
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        //     alignItems: 'center'
                        // },
                        // '@media (min-width: 375px) and (max-width:425px)': {
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        //     alignItems: 'center'
                        // },
                        // '@media (min-width: 425px) and (max-width:760px)': {
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        //     alignItems: 'center'
                        // }
                    }
                },
                {
                    props: {
                        classes: 'footers_grid'
                    },
                    style: {
                        marginTop: '10px !important',
                        // marginLeft: '35px !important',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: '0px !important',
                            marginLeft: '0px !important'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: '0px !important',
                            marginLeft: '0px !important'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            marginTop: '0px !important',
                            marginLeft: '0px !important'
                        }
                    }
                },
                {
                    props: {
                        classes: 'banner_grid_data'
                    },
                    style: {
                        marginTop: '2%',
                        '@media (min-width: 280px) and (max-width:315px)': {
                            // backgroundColor:'red',
                            marginTop: '-9%',
                            textAlign: 'center',
                            justifyContent: 'center'
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            // backgroundColor:'red',
                            marginTop: '-10%',
                            textAlign: 'center',
                            justifyContent: 'center'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: '-10%',
                            textAlign: 'center',
                            justifyContent: 'center'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            marginTop: '-15%',
                            textAlign: 'center',
                            justifyContent: 'center'
                        },
                        '@media (min-width: 1340px) and (max-width:1440px)': {
                            marginTop: '10%',
                            textAlign: 'center',
                            justifyContent: 'center'
                        }
                    }
                },
                {
                    props: {
                        classes: 'banner_grid_datas'
                    },
                    style: {
                        marginTop: '12%',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: '-5%'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: '-6%'
                        }
                    }
                },
                {
                    props: {
                        classes: 'grid_shadow'
                    },
                    style: {
                        '&:hover': {
                            boxShadow: '  55px 55px 15px -50px rgb(0 0 0 / 20%)',
                            cursor: 'pointer'
                        }
                    }
                },
                {
                    props: {
                        classes: 'banner_grid'
                    },
                    style: {
                        // zIndex: '99',
                        position: 'absolute',
                        right: '4.5rem',
                        bottom: '1rem',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            bottom: '0rem',
                            right: '1rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            bottom: '0rem',
                            right: '1rem'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            bottom: '0rem',
                            right: '1rem'
                        },
                        '@media (min-width: 700px) and (max-width:768px)': {
                            bottom: '0rem',
                            right: '1rem'
                        },
                        '@media (min-width: 768px) and (max-width:1024px)': {
                            bottom: '0rem',
                            right: '1rem'
                        },
                        '@media (min-width: 1025px) and (max-width:1499px)':{
                            bottom: '0rem',
                            right: '9rem',
                        },
                        '@media (min-width: 1500px) and (max-width:1900px)':{
                            bottom: '0rem',
                            right: '13.5rem',
                        },
                        '@media (min-width: 1901px) and (max-width:2560px)':{
                            bottom: '0rem',
                            right: '24rem',
                        }
                    }
                },
                {
                    props: {
                        classes: 'services'
                    },
                    style: {
                        fontWeight: FONT.WEIGHT.REGULAR,
                        fontSize: FONT.SIZES[12],
                        fontFamily: 'Poppins',
                        size: 'large',
                        minWidth: '350px',
                        margin: '0.3rem',

                        backgroundColor: '#D6D5E1',
                        borderRadius: '4rem',
                        textTransform: 'initial',
                        width: '195px'
                    }
                },
                {
                    props: {
                        classes: 'product_grid'
                    },
                    style: {
                        marginTop: '5rem',
                        marginBottom: '5rem',
                        margin: 3
                    }
                },
                {
                    props: {
                        classes: 'service_grid'
                    },
                    style: {
                        marginTop: '-3rem',

                        '@media (max-width: 768px) and (max-width:1024px)': {
                            marginTop: '0'
                        }
                    }
                },
                {
                    props: {
                        classes: 'Education_grid'
                    },
                    style: {
                        marginTop: '1rem',
                        // paddingTop: '0.5rem',
                        // paddingBottom: '3rem',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            paddingTop: '0.5rem',
                            paddingBottom: '0rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            paddingTop: '0.5rem',
                            paddingBottom: '0rem'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            paddingTop: '0.5rem',
                            paddingBottom: '0rem'
                        }
                    }
                },

                {
                    props: {
                        classes: 'grid_1'
                    },
                    style: {
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: 0,
                            paddingTop: 0
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: 0,
                            paddingTop: 0
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            marginTop: 0,
                            paddingTop: 0
                        }
                    }
                },

                {
                    props: {
                        classes: 'grid_2'
                    },
                    style: {
                        // marginTop: '2rem',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: '0px'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: '0px'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            marginTop: '0px'
                        }
                    }
                },

                {
                    props: {
                        classes: 'desc_grid'
                    },
                    style: {
                        // margin: '16px !important',
                        marginTop: '28px !important',
                        marginBottom: '28px !important',
                        '@media (max-width: 320px)': {
                            margin: '2px'
                        }
                    }
                },

                {
                    props: {
                        classes: 'imageCenter'
                    },
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
                },

                {
                    props: {
                        classes: 'recycle_grid'
                    },
                    style: {
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: '0'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: '0'
                        },
                        '@media (min-width: 425px) and (max-width:725px)': {
                            marginTop: '0'
                        }
                    }
                },
                {
                    props: {
                        classes: 'careers_grid'
                    },
                    style: {
                        marginTop: '2rem',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: '3rem',
                            textAlign: 'center'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: '3rem',
                            textAlign: 'center'
                        },
                        '@media (min-width: 420px) and (max-width:700px)': {
                            marginTop: '3rem'
                        },
                        '@media (min-width: 700px) and (max-width:1024px)': {
                            marginTop: '2rem'
                        }
                    }
                },
                {
                    props: {
                        classes: 'sub_service'
                    },
                    style: {
                        marginTop: '-3rem',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: '1rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: '1rem'
                        },
                        '@media (max-width: 420px) and (max-width:700px)': {
                            marginTop: '1rem'
                        },
                        '@media (max-width: 768px) and (max-width:1024px)': {
                            marginTop: '0'
                        },
                        '@media (max-width: 1024px)': {
                            marginTop: 0
                        }
                    }
                },
                {
                    props: {
                        classes: 'form_grid'
                    },
                    style: {
                        '@media (max-width: 375px)': {
                            marginTop: 0
                        },
                        '@media (max-width: 768px) and (max-width:1024px)': {
                            marginTop: 0,
                            paddingTop: '1rem'
                            // paddingBottom:'3rem'
                        },
                        '@media (max-width: 1024px) and (max-width:1440px)': {
                            marginTop: 0,
                            paddingTop: '1rem'
                        }
                    }
                },
                {
                    props: {
                        classes: 'RepoImage'
                    },
                    style: {
                        position: 'relative',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginLeft: 0,
                            height: '180px',
                            marginBottom: '2rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginLeft: 0,
                            height: '180px !important',
                            marginBottom: '2rem'
                        },
                        '@media (min-width: 420px) and (max-width:700px)': {
                            marginLeft: 0,
                            height: '180px !important',
                            marginBottom: '2rem'
                        },
                        '@media (min-width: 768px) and (max-width:1024px)': {
                            marginLeft: 0,
                            height: '165px !important'
                        }
                    }
                },
                {
                    props: {
                        classes: 'about_globe'
                    },
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '-5rem !important',
                        zIndex: '99 !important'
                    }
                },
                {
                    props: {
                        classes: 'about_globes'
                    },
                    style: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '-3rem !important',
                        zIndex: '99 !important'
                    }
                },
                {
                    props: {
                        classes: 'Imagedata'
                    },
                    style: {
                        position: 'relative',
                        // marginLeft: '16.5rem',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginLeft: '1rem',
                            height: '180px'
                            // paddingBottom: '13rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginLeft: '1rem',
                            height: '200px !important'
                        },
                        '@media (min-width: 425px) and (max-width:768px)': {
                            marginLeft: '1rem',
                            height: '190px !important'
                        },
                        '@media (min-width: 768px) and (max-width:1024px)': {
                            marginLeft: 0,
                            height: '190px !important'
                        }
                    }
                },
                {
                    props: {
                        classes: 'data_grid'
                    },
                    style: {
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginBottom: '1rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginBottom: '1rem'
                        }
                    }
                },
                {
                    props: {
                        classes: 'data_eng'
                    },
                    style: {
                        marginTop: '-5rem',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            marginTop: 0
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            marginTop: 0
                        },

                        '@media (min-width: 425px) and (max-width:768px)': {
                            marginTop: 0
                        },
                        '@media (min-width: 768px) and (max-width:1204px)': {
                            marginTop: 0
                        }
                    }
                }
            ]
        },
        MuiTab: {
            variants: [{
                props: {
                    classes: 'tab'
                },
                style: {
                    fontSize: FONT.SIZES[16],

                    color: PALETTE.MEDIUN_DARK_GRAY,
                    padding: '1rem',

                    paddingBottom: '1rem',
                    margin: '1px',
                    fontFamily: 'Poppins',
                    textTransform: 'none',
                    '& .MuiTabs-indicator': {
                        display: 'none'
                    },
                    '& .MuiTab-root ': {
                        color: 'black !important'
                    },
                    '& .Mui-selected': {
                        color: '#BECE20 !important'
                    },
                    '@media (max-width: 375px)': {
                        margin: '20px !important',
                        width: '250px !important'
                    },
                    '@media (max-width: 425px)': {
                        margin: '35px !important',
                        width: '250px !important'
                    },
                    '@media (max-width: 768px)': {
                        float: 'right',
                        margin: '45px !important',
                        width: '250px !important'
                    }
                }
            }]
        },
        MuiBox: {
            variants: [{
                props: {
                    className: 'box'
                },
                style: {
                    background: PALETTE.PRIMARY,
                    height: '50vh',
                    position: 'relative'
                }
            }]
        },
        MuiInputLabel: {
            variants: [{
                props: {
                    classes: 'carddata'
                },
                style: {
                    background: PALETTE.SENARY,
                    border: `1px solid ${PALETTE.QUATERNARY}`,
                    borderRadius: `12px`,
                    padding: `0.5rem 1rem`,
                    minWidth: `148px`,
                    cursor: 'pointer'
                }
            }]
        },
        // Used
        MuiPaper: {
            variants: [{
                    props: {
                        variant: 'card'
                    },
                    style: {
                        width: '100%',
                        height: '100%',
                        padding: '50px',
                        backgroundColor: '#ECEFF6'
                    }
                },
                {
                    props: {
                        variant: 'cards'
                    },
                    style: {
                        width: '350px',
                        padding: `0rem 1rem 1rem 1rem !important`,

                        background: PALETTE.HAWKES_BLUE
                    }
                },
                {
                    props: {
                        variant: 'careers_data_card'
                    },
                    style: {
                        minWidth: '180px',
                        height: 'auto',

                        padding: '2rem',
                        margin: '4rem',

                        // marginTop: "5rem",

                        boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                    }
                },

                {
                    props: {
                        variant: 'subcards'
                    },
                    style: {
                        width: '335px',

                        background: PALETTE.WHITE,
                        // fontWeight: FONT.WEIGHT.POPPINS,
                        fontSize: FONT.SIZES[16],
                        lineHeight: '145%',
                        color: PALETTE.MEDIUN_DARK_GRAY,

                        height: '100%',
                        position: 'relative',
                        marginLeft: '15px',
                        marginTop: '45px'
                    }
                },
                {
                    props: {
                        variant: 'careers'
                    },
                    style: {
                        height: '30%',
                        width: '100%',

                        // paddingBottom: "5rem !important",
                        display: 'flex',

                        backgroundImage: 'linear-gradient(180deg, #C1C5C7A7, #FFFFFF76)'
                    }
                },
                {
                    props: {
                        variant: 'careers_cards'
                    },
                    style: {
                        height: '100%',
                        width: '100%',
                        // position:'fixed',

                        paddingBottom: '5.5rem !important',
                        display: 'flex',

                        backgroundImage: 'linear-gradient(180deg, #C1C5C7A7, #FFFFFF76)'
                    }
                },
                {
                    props: {
                        variant: 'footer'
                    },
                    style: {
                        overflow: 'hidden',

                        background: PALETTE.SOLITUDE,
                        height: '200px !important',
                        bottom: 0,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'relative',
                        '@media screen and (max-width: 375px)': {
                            height: 'auto !important'
                        },
                        '@media screen and (max-width: 425px)': {
                            height: 'auto !important'
                        },
                        '@media screen and (max-width: 768px)': {
                            height: 'auto !important'
                        }
                    }
                },
                // about us
                {
                    props: {
                        variant: 'about_banner'
                    },
                    style: {
                        overflow: 'hidden',
                        // padding: 8,
                        backgroundColor: '#ECEFF6',
                        justifyContent: 'center'
                    }
                },
                {
                    props: {
                        variant: 'client_banner'
                    },
                    style: {
                        overflow: 'hidden',
                        backgroundColor: '#ECEFF6',
                        justifyContent: 'center',
                        height: '40px'
                    }
                },
                {
                    props: {
                        variant: 'team_banner'
                    },
                    style: {
                        overflow: 'hidden',
                        // padding: 8,
                        backgroundColor: '#ECEFF6',
                        justifyContent: 'center',
                        height: '130px'
                    }
                },
                {
                    props: {
                        variant: 'imageBanner'
                    },
                    style: {
                        overflow: 'hidden',
                        // paddingTop: '1rem',
                        background: '#4f5b60',
                        // paddingBottom: '20rem',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'relative'
                    }
                },
                {
                    props: {
                        variant: 'capability_banner'
                    },
                    style: {
                        backgroundColor: '#ECEFF6',
                        opacity: '1px !important',
                        height: 'auto',
                        width: '100% !important',
                        // marginTop: "2rem",
                        paddingTop: '2rem',
                        paddingBottom: '3rem',
                        // marginTop: '-0.5rem',

                        position: 'relative',
                        boxShadow: 'none',

                        backgroundSize: 'auto'
                    }
                },
                {
                    props: {
                        variant: 'product_education_banner'
                    },
                    style: {
                        overflow: 'hidden',

                        backgroundColor: '#ECEFF6',
                        height: '100%',
                        paddingTop: '2rem',
                        paddingBottom: '2rem',

                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'relative',
                        zIndex: '0',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        }
                    }
                },
                {
                    props: {
                        variant: 'hybrid_education_banner'
                    },
                    style: {
                        overflow: 'hidden',

                        backgroundColor: '#ECEFF6',
                        height: '100%',
                        paddingTop: '2rem',
                        paddingBottom: '2rem',

                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'relative',
                        zIndex: '0',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        }
                    }
                },
                {
                    props: {
                        variant: 'data_engineering_banner'
                    },
                    style: {
                        overflow: 'hidden',

                        backgroundColor: '#ECEFF6',
                        height: '100%',
                        paddingTop: '2rem',
                        paddingBottom: '2rem',

                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'relative',
                        zIndex: '0',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        },
                        '@media (min-width: 425px) and (max-width:700px)': {
                            paddingBottom: 0,
                            paddingTop: '1rem'
                        }
                    }
                },
                {
                    props: {
                        variant: 'capability_banner_paper'
                    },
                    style: {
                        // display: 'flex',
                        backgroundColor: 'white !important',
                        width: '92%',
                        height: '105px',
                        // boxShadow: "none",
                        float: 'right !important',
                        padding: '0.6rem !important',
                        borderRadius: '0px !important',

                        marginTop: '-2.5rem !important',
                        position: 'relative'
                    }
                },
                {
                    props: {
                        variant: 'capability_banner_paper_careers'
                    },
                    style: {
                        // display: 'flex',
                        backgroundColor: 'white !important',
                        width: '92%',
                        height: '90px',
                        borderRadius: '0px !important',
                        float: 'right !important',
                        padding: '1rem !important',

                        marginTop: '-4rem !important',

                        boxShadow: '0 1px 1px 1px rgba(0,0,0,0.1)'
                    }
                },
                {
                    props: {
                        variant: 'Customer_Banner'
                    },
                    style: {
                        overflow: 'hidden',

                        backgroundColor: '#ECEFF6',
                        height: '100%',

                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'fixed'
                    }
                },
                {
                    props: {
                        variant: 'Customer_Banner1'
                    },
                    style: {
                        overflow: 'hidden',

                        backgroundColor: '#D6D5E1 !important',
                        height: '100%',

                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover',
                        borderRadius: '1px',
                        position: 'fixed'
                    }
                },
                {
                    props: {
                        variant: 'Avatar'
                    },
                    style: {
                        position: 'relative',
                        width: '120px !important',
                        height: '120px !important',
                        borderRadius: '50% !important',
                        border: '4px solid #BECE20',
                        '&:hover': {
                            border: '4px solid #BECE20'
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            width: '100px !important',
                            height: '100px !important'
                        }
                    }
                }
            ]
        },
        MuiDivider: {
            variants: [{
                    props: {
                        variant: 'Careers-bordered'
                    },
                    style: {
                        border: `2px solid ${PALETTE.PRIMARY}`,
                        width: '4rem',
                        // borderBottomWidth: 'medium',
                        marginBottom: '5px'
                    }
                },
                {
                    props: {
                        variant: 'capability-bordered'
                    },
                    style: {
                        border: `2px solid ${PALETTE.PRIMARY}`,
                        width: '4rem',

                        // borderBottomWidth: 'medium',
                        marginBottom: '5px'
                    }
                },
                {
                    props: {
                        variant: 'card_divider'
                    },
                    style: {
                        border: `0.5px solid ${PALETTE.PRIMARY}`,
                        width: '4rem',
                        borderBottomWidth: 'thin',
                        marginBottom: '2px'
                    }
                },
                {
                    props: {
                        variant: 'card_carrer'
                    },
                    style: {
                        border: `0.5px solid ${PALETTE.PRIMARY}`,
                        width: '4rem',
                        borderBottomWidth: 'thin',
                        marginTop: '18px'
                    }
                },

                {
                    props: {
                        variant: 'banner_divider'
                    },
                    style: {
                        border: `1px solid ${PALETTE.PRIMARY}`,
                        height: '15px',
                        borderBottomWidth: 'thin',
                        marginTop: '25px',
                        textAlign: 'center'
                    }
                }
            ]
        },

        MuiStack: {
            variants: [{
                    props: {
                        variant: 'careers_stack'
                    },
                    style: {
                        textAlign: 'center',
                        fontSize: '1rem',
                        fontFamily: 'Poppins',
                        marginBottom: '1rem 3rem',
                        '@media (max-width: 320px)': {
                            fontSize: '0.1rem'
                        }
                    }
                },
                {
                    props: {
                        classes: 'stack'
                    },
                    style: {
                        '@media (max-width: 320px)': {
                            marginTop: '-5rem'
                        }
                    }
                },
                {
                    props: {
                        classes: 'footerstack'
                    },
                    style: {
                        marginTop: '20px',
                        marginLeft: '10px',
                        '@media screen and (max-width: 320px)': {
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            marginLeft: 0
                        },
                        '@media screen and (max-width: 375px)': {
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            marginLeft: 0
                        },
                        '@media screen and (max-width: 425px)': {
                            marginTop: '1rem',
                            marginBottom: '1rem',
                            marginLeft: 0
                        }
                    }
                },

                {
                    props: {
                        classes: 'emailAddress'
                    },
                    style: {
                        marginTop: '5px',
                        textDecoration: 'none !important',
                        justifyContent: 'center',
                        '&:hover': {
                            color: '#A6B50C',
                            cursor: 'pointer'
                        },
                        '@media (min-width: 320px) and (max-width:375px)': {
                            justifyContent: 'center',
                            fontSize: '13px !important'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            justifyContent: 'center',
                            fontSize: '13px !important'
                        },
                        '@media (min-width: 425px) and (max-width:760px)': {
                            justifyContent: 'center',
                            fontSize: '13px !important'
                        },
                        '@media screen and (max-width: 768px)': {
                            fontSize: '13px !important'
                        },
                        '@media screen and (max-width: 1204px)': {
                            fontSize: '13px !important'
                        }
                    }
                },

                {
                    props: {
                        classes: 'location'
                    },
                    style: {
                        marginTop: '5px !important',
                        justifyContent: 'center',
                        alignItems:'center',
                        textAlign:'center',
                        '@media (min-width: 320px) and (max-width:375px)': {
                            justifyContent: 'center'
                        },
                        '@media (min-width: 375px) and (max-width:425px)': {
                            justifyContent: 'center'
                        },
                        '@media (min-width: 425px) and (max-width:760px)': {
                            justifyContent: 'center'
                        }
                    }
                }
            ]
        },

        MuiCard: {
            variants: [{
                    props: {
                        variant: 'cardss'
                    },
                    style: {
                        width: '100%',
                        height: '100%',
                        position: 'relative',

                        background: PALETTE.HAWKES_BLUE
                    }
                },

                {
                    props: {
                        classes: 'subCard'
                    },
                    style: {
                        background: PALETTE.WHITE,

                        minWidth: `148px`
                    }
                },
                {
                    props: {
                        variant: 'aboutcard'
                    },
                    style: {
                        minWidth: '250px',
                        Height: '200px'
                    }
                },
                {
                    props: {
                        variant: 'capability_card'
                    },
                    style: {
                        backgroundColor: '#D6D5E1 !important',
                        borderRadius: '0px !important',

                        minHeight: '150px !important',
                        maxWidth: '100% !important',

                        display: 'flex'
                    }
                },
                {
                    props: {
                        variant: 'careers_card'
                    },
                    style: {
                        backgroundColor: '#D6D5E1 !important',

                        minHeight: '170px !important',
                        maxWidth: '100% !important',
                        borderRadius: '0px !important',
                        display: 'flex',
                        zIndex: 10,
                        position: 'relative'
                    }
                },

                {
                    props: {
                        variant: 'about_card'
                    },
                    style: {
                        minWidth: '50%',
                        minHeight: '290px',
                        position: 'relative',
                        marginTop: '-2rem',
                        boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                        zIndex: 10,
                        '@media (max-width: 320px)': {
                            marginBottom: '5rem !important'
                        },
                        '@media (max-width: 375px)': {
                            marginBottom: '5rem !important'
                        },
                        '@media (max-width: 425px)': {
                            marginBottom: '5rem !important'
                        },
                        '@media (max-width: 768px)': {
                            marginBottom: '5rem !important'
                        }
                    }
                },
                {
                    props: {
                        variant: 'about_cards'
                    },
                    style: {
                        boxShadow: '0 0 10px rgb(0 0 0 / 0.2)',
                        borderRadius: '50%',
                        width: 170,
                        height: 170,
                        background: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
                },
                {
                    props: {
                        variant: 'res_about_cards'
                    },
                    style: {
                        boxShadow: '0 0 10px rgb(0 0 0 / 0.2)',
                        borderRadius: '50%',
                        width: 80,
                        height: 80,
                        background: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '10px'
                    }
                }
            ]
        },

        MuiLink: {
            variants: [{
                    props: {
                        classes: 'LinkData'
                    },
                    style: {
                        color: '#5F6368',
                        textDecoration: 'none ',
                        padding: '12px',
                        margin: '10px ',
                        fontFamily: 'Raleway',
                        minWidth: 'auto',
                        letterSpacing: '1px',
                        fontWeight: '600',
                        fontSize: '15px',
                        '&:hover': {
                            color: '#A6B50C',
                            cursor: 'pointer'
                        },
                        '& .MuiAppBar-root ': {
                            color: '#4F5B60 '
                        },
                        '& .Mui-selected': {
                            color: '#A6B50C'
                        },
                        '&.MuiMenu-root': {
                            color: '#A6B50C '
                        },
                        '@media screen and (max-width: 375px)': {
                            display: 'grid'
                        },
                        '@media screen and (max-width: 425px)': {
                            display: 'grid'
                        },
                        '@media screen and (max-width: 768px)': {
                            display: 'grid'
                        }
                    }
                },
                {
                    props: {
                        classes: 'LinkDataOC'
                    },
                    style: {
                        // scrollMarginBlockStart: '110px',
                        color: '#5F6368',
                        textDecoration: 'none ',
                        padding: '12px',
                        margin: '10px ',
                        fontFamily: 'Raleway',
                        minWidth: 'auto',
                        letterSpacing: '1px',
                        fontWeight: '600',
                        fontSize: '15px',
                        scrollMarginBlockStart: '140px',
                        '&:hover': {
                            color: '#A6B50C',
                            cursor: 'pointer'
                        },
                        '& .MuiAppBar-root ': {
                            color: '#4F5B60 '
                        },
                        '& .Mui-selected': {
                            color: '#A6B50C'
                        },
                        '&.MuiMenu-root': {
                            color: '#A6B50C '
                        },
                        '@media screen and (max-width: 375px)': {
                            display: 'grid'
                        },
                        '@media screen and (max-width: 425px)': {
                            display: 'grid'
                        },
                        '@media screen and (max-width: 768px)': {
                            display: 'grid'
                        }
                    }
                },
                {
                    props: {
                        classes: 'footer_LinkData'
                    },
                    style: {
                        color: '#5F6368',
                        textDecoration: 'none ',
                        padding: '12px',
                        margin: '10px ',
                        fontFamily: 'Raleway',
                        letterSpacing: '1px',
                        fontWeight: '600',
                        fontSize: '15px',
                        '&:hover': {
                            color: '#A6B50C',
                            cursor: 'pointer'
                            // fontSize: '17px'
                        },
                        '& .MuiAppBar-root ': {
                            color: '#4F5B60 '
                        },
                        '& .Mui-selected': {
                            color: '#A6B50C'
                        },
                        '&.MuiMenu-root': {
                            color: '#A6B50C '
                        },
                        '@media screen and (max-width: 375px)': {
                            display: 'grid'
                        },
                        '@media screen and (max-width: 425px)': {
                            display: 'grid'
                        },
                        '@media screen and (max-width: 768px)': {
                            display: 'grid'
                        }
                    }
                },
                {
                    props: {
                        classes: 'footerData'
                    },
                    style: {
                        fontSize: '11px !important',
                        whiteSpace: 'nowrap',
                        color: '#5F6368',
                        display: 'flex',
                        justifyContent: 'center !important',

                        fontFamily: 'Poppins !important',

                        letterSpacing: '1px',
                        textDecoration: 'none !important',
                        fontWeight: '500 !important',

                        padding: '0px',

                        '&:hover': {
                            color: '#A6B50C',
                            cursor: 'pointer'
                        },
                        '@media screen and (max-width: 320px)': {
                            alignItems: 'center'
                        },
                        '@media screen and (max-width: 375px)': {
                            alignItems: 'center'
                        },
                        '@media screen and (max-width: 425px)': {
                            display: 'flex',

                            alignItems: 'center',
                            margin: '12px 0px 0px 0px'
                        },

                        '@media screen and (max-width: 1024px)': {
                            display: 'block'
                        },

                        '@media screen and (max-width: 768px)': {
                            display: 'flex',

                            alignItems: 'center'
                        }
                    }
                },
                {
                    props: {
                        classes: 'customFooter'
                    },
                    style: {
                        fontSize: '11px',
                        whiteSpace: 'nowrap',
                        color: '#5F6368',
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                        fontFamily: 'Poppins',

                        letterSpacing: '1px',
                        textDecoration: 'none',
                        fontWeight: '500 !important',
                        '&:hover': {
                            color: '#A6B50C',
                            cursor: 'pointer'
                        }
                    }
                },
                {
                    props: {
                        classes: 'privacyFooter'
                    },
                    style: {
                        fontSize: '11px',
                        // whiteSpace: 'nowrap',
                        color: '#5F6368',
                        // display: 'flex',
                        // // justifyContent: 'center',
                        marginTop: '10px',
                        fontFamily: 'Poppins',

                        letterSpacing: '1px',
                        textDecoration: 'none',
                        fontWeight: '500 !important',
                        zIndex: '99 !important',
                        '&:hover': {
                            color: '#A6B50C',
                            cursor: 'pointer'
                        }
                    }
                }
            ]
        },

        MuiAppBar: {
            variants: [{
                props: {
                    classes: 'container'
                },
                style: {
                    background: '#fff',
                    boxShadow: 'none !important'
                }
            }]
        },
        MuiFab: {
            variants: [{
                props: {
                    classes: 'fab'
                },
                style: {
                    position: 'fixed',
                    bottom: '20px',
                    right: '16px',
                    color: '#BECE20',
                    zIndex: '99'
                }
            }]
        },
        MuiTextField: {
            variants: [{
                    props: {
                        classes: 'textfield'
                    },
                    style: {
                        margin: '0.5rem',
                        fontFamily: 'Poppins !important',
                        color: '#343333',
                        fontSize: '12px',
                        '.MuiFormLabel-asterisk ': {
                            color: 'red !important'
                        }
                    }
                },
                {
                    props: {
                        classes: 'commentTextField'
                    },
                    style: {
                        margin: '1rem',
                        fontFamily: 'Poppins !important',
                        color: '#343333',
                        fontSize: '12px',
                        '.MuiFormLabel-asterisk ': {
                            color: 'red !important'
                        }
                    }
                }
            ]
        }
    }
});

export default theme;